<template>
    <v-dialog v-model="dialog" fullscreen
              transition="dialog-bottom-transition" persistent>
          <v-card>

              <v-toolbar dark>
                  <v-card-title class="headline">
                      Upload applicant photo and document
                      <hr>
                  </v-card-title>

                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="closeDialogWithConfirmation">
                          <i class="fas fa-times"></i>
                      </v-btn>
                  </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col md="6">
                              <v-text-field label="Enrollment full name" :value="enrollment_full_name" readonly></v-text-field>
                          </v-col>
                          <v-col md="6">
                              <v-text-field label="Enrollment Symbol No." :value="enrollment_symbol_no" readonly></v-text-field>
                          </v-col>
                          <div class="col-12">
                              <h5> <b>Applicant photo</b> </h5>
                          </div>
                          <v-col cols="12">
                              <strong>
                                  <v-switch
                                      v-model="useWebcam"
                                      :label="useWebcam ? 'Camera' : 'Upload'"
                                  ></v-switch>

                              </strong>
                          </v-col>
                          <v-col cols="8" v-if="!useWebcam">
                              <v-file-input outlined dense
                                  v-model="photo" prepend-inner-icon="mdi-file"
                                  prepend-icon=""  :accept="'image/jpeg,image/png'"
                                  @change="previewUploadedFile" label="Applicant photo">
                              </v-file-input>
                          </v-col>

                          <!-- crop uploaded image -->
                          <v-col cols="12" md="6" v-if="!useWebcam">
                              <div v-if="previewCapturedPhoto">
                                  <v-btn
                                      class="text-white"
                                      color="black"
                                      small
                                      @click="openCropperForFileUpload"
                                  >
                                      {{ cropUploadFile ? 'Cancel' : 'Crop' }}
                                  </v-btn>

                                  <v-btn
                                      v-if="cropUploadFile"
                                      class="ml-3 text-white"
                                      color="green"
                                      small
                                      @click="cropImage"
                                  >
                                      Save selection
                                  </v-btn>
                              </div>
                              <br>
                              <div v-if="cropUploadFile" >
                                  <cropper
                                      class="cropper"
                                      :src="previewCapturedPhoto"
                                      @crop="cropImage"
                                      :default-size="defaultSize"
                                      v-model="croppedPic"
                                      :output-image-quality="0.7"
                                      :output-image-type="'jpeg'"

                                      ref="cropper"
                                  />
                              </div>
                          </v-col>
                          <v-col cols="12" md="6" v-if="!useWebcam">
                              <div class="" v-if="!cropUploadFile">
                                  <img  :src="previewCapturedPhoto" class="rounded" style="max-width: 100%; height: 550px; object-fit:contain;" alt="">
                              </div>
                              <div v-if="cropUploadFile" class="mt-2">
                                  <img v-if="croppedPic" :src="croppedPic" class="mt-4 border rounded" style="border:6px solid #efefef; max-width: 100%; height: 550px; object-fit:contain;" alt="Cropped Image">
                              </div>
                          </v-col>
                          <!-- end crop uploaded image -->

                          <!-- webcam section -->
                        <v-col v-if="useWebcam" cols="12" md="6">
                            <div class="camera-box py-3 mt-20">
                                <div style="display: flex; justify-content: center;">
                                    <img 
                                        style="height: 25px;" 
                                        v-if="isCameraOpen"
                                        src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                                        class="button-img camera-shoot" 
                                        @click="capture"
                                    />
                                    <div class="camera-button">
                                        <button 
                                            type="button" 
                                            class="button is-rounded cam-button"
                                            style="margin-left: 40%; background-color: white; border: 0px;"
                                            @click="toggleCamera"
                                        >
                                        <span v-if="!isCameraOpen">
                                            <img 
                                                style="height: 25px;" 
                                                class="button-img"
                                                src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                                            >
                                            </span>
                                            <span v-else>
                                            <img style="height: 25px;" class="button-img"
                                                            src="https://img.icons8.com/material-outlined/50/000000/cancel.png">
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div style="height: 250px" class="text-center">
                                    <div v-if="isCameraOpen" class="camera-canvas my-5">
                                        <video ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay></video>
                                        <canvas v-show="false" id="photoTaken" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
                                    </div>
                                </div>
                                <div class="mt-2 text-center">
                                    <v-btn
                                        :loading="isCapturing"
                                        @click="capture"
                                        medium
                                        v-if="isCameraOpen"
                                        class="btn btn-primary ml-3 mb-2"
                                        dark
                                    >
                                        Capture
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <!-- end webcam section -->

                        <!-- webcam cropper section -->
                        <v-col v-if="useWebcam" cols="12" md="6">
                            <cropper
                                class="cropper"
                                :src="previewCapturedPhoto"
                                @crop="cropImage"
                                v-model="croppedPic"
                                :output-image-quality="0.7"
                                :output-image-type="'jpeg'"
                                :output-max-height="800"
                                :output-max-width="800"
                                ref="cropper"
                            />


                            <div v-if="previewCapturedPhoto">
                                <v-btn
                                    class="mt-3 text-white"
                                    color="green"
                                    small
                                    @click="cropImage"
                                >
                                    Save selection
                                </v-btn>

                                <v-btn
                                    class="mt-3 ml-3 text-white"
                                    color="orange"
                                    small
                                    @click="recapture"
                                >
                                    Recapture
                                </v-btn>

                                <v-btn
                                    class="mt-3 ml-3 text-white"
                                    color="red"
                                    small
                                    v-if="croppedPic"
                                    @click="resetCroppedImage"
                                >
                                    Reset selection
                                </v-btn>
                            </div>

                            <div>
                                <img v-if="croppedPic" :src="croppedPic" class="mt-4 border rounded" style="border:6px solid #efefef;" alt="Cropped Image">
                            </div>
                        </v-col>
                        <!-- end webcam cropper section -->

                          <div class="col-12">
                              <h5>
                                  <b>Additional documents</b></h5>
                          </div>
                          <div class="col-4">
                              <v-text-field outlined dense v-model="document_one_title" label="Attachment one title"></v-text-field>
                          </div>
                          <div class="col-8">
                              <v-file-input outlined dense v-model="document_one" label="Attachment one" prepend-inner-icon="mdi-file"
                                            prepend-icon=""></v-file-input>
                          </div>
                          <div class="col-4">
                              <v-text-field outlined dense v-model="document_two_title" label="Attachment two title"></v-text-field>
                          </div>
                          <div class="col-8">
                              <v-file-input outlined dense v-model="document_two" label="Attachment two" prepend-inner-icon="mdi-file"
                                            prepend-icon=""></v-file-input>
                          </div>
                          <div class="col-4">
                              <v-text-field outlined dense v-model="document_three_title" label="Attachment three title"></v-text-field>
                          </div>
                          <div class="col-8">
                              <v-file-input outlined dense v-model="document_three" label="Attachment three " prepend-inner-icon="mdi-file"
                                            prepend-icon=""></v-file-input>
                          </div>


<!--                          <v-col md="12" v-if="is_payment_completed">
                              <button class="preview-button">

                              </button>
                              <v-btn class="ma-2" @click="previewInvoice" v-if="checkIsAccessible('pre-registration', 'preview-invoice')"
                                  outlined color="teal">
                                  <v-icon left>mdi-printer</v-icon>
                                  Preview Invoice
                              </v-btn>
                              <v-btn @click="previewAdmitCard" v-if="checkIsAccessible('pre-registration', 'preview-pdf')" class="ma-2"
                                  outlined
                                  color="indigo">
                                  <v-icon left>mdi-card-account-details-outline</v-icon>
                                  Preview admit card
                              </v-btn>

                          </v-col>-->
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>

                      <v-spacer></v-spacer>
                      <v-btn class="btn btn-standard" @click="closeDialogWithConfirmation" depressed>Cancel</v-btn>
                      <v-btn  :loading="loading" class="btn btn-primary text-white" depressed @click.prevent="saveData">Upload</v-btn>

              </v-card-actions>

          </v-card>
      </v-dialog>
</template>
<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import {API_URL} from "@/core/config";
const enrollmentService=new UserEnrollmentService();
export default {
    components: {
        Cropper,

    },
    data(){
        return{
            mark_as_paid:false,
            enrollment_full_name:null,
            enrollment_symbol_no:null,
            symbol_no:null,
            dialog:false,
            loading: false,
            photo:null,
            useWebcam: true,
            isCameraOpen: false,
            canvasHeight:250,
            canvasWidth:300,
            capturedPhotoFile: null,
            previewCapturedPhoto: null,
            isCapturing: false,
            croppedPic: null,
            coordinates: {
                width: 0,
                height: 0,
                left: 0,
                top: 0,
            },
            cropUploadFile: false,
            marksheet: null,
            document_one: null,
            document_two: null,
            document_three: null,
            document_one_title: null,
            document_two_title: null,
            document_three_title: null,
            enrollmentId:null,
            is_payment_completed:false,
            remarks:null,
        }
    },
    methods:{
        resetForm(){
            this.photo = null;
            this.useWebcam = true;
            this.image = null;
            this.isCameraOpen = false;
            this.canvasHeight =250;
            this.canvasWidth =300;
            this.capturedPhotoFile = null;
            this.previewCapturedPhoto = null;
            this.isCapturing = false;
        },
        openDialog(){
          this.dialog=true;
        },
        closeDialog(){
            this.dialog=false;
            this.$router.push({
                name: 'pre-registration-list',
            });
            this.$tabs.close();

        },
        closeDialogWithConfirmation(){
            this.$confirm({
                message: `Are you sure quite this page?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                callback: confirm => {
                    if (confirm) {
                        this.dialog=false;
                        this.$router.push({
                            name: 'pre-registration-list',
                        });
                        this.$tabs.close();
                    }
                }
            });

        },
        openUploadDocumentAndPhotoDialog(enrollment,paymentStatus,remarks){
            if(enrollment){
                this.openDialog();
                this.enrollmentId=enrollment.id;
                if(enrollment.full_name){
                    this.enrollment_full_name=enrollment.full_name;
                }
                if(enrollment.symbol_no){
                    this.enrollment_symbol_no=enrollment.symbol_no;
                }
                this.is_payment_completed=paymentStatus;
                if(remarks){
                  this.remarks=remarks;
                }
            }
        },
        previewUploadedFile(){
            if(this.photo){
                this.previewCapturedPhoto= URL.createObjectURL(this.photo);
            }else{
                this.previewCapturedPhoto=null;
            }
        },
        toggleCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.startCameraStream();
                this.resetCroppedImage();
                this.previewCapturedPhoto = null;
            }
        },
        capture() {
            const FLASH_TIMEOUT = 50;
            let self = this;
            this.isCapturing = true;
            setTimeout(() => {
                const canvas = self.$refs.canvas;
                const context = self.$refs.canvas.getContext('2d');
                context.drawImage(self.$refs.camera, 0, 0, self.canvasWidth, self.canvasHeight);
                const dataUrl = self.$refs.canvas.toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");

                self.previewCapturedPhoto = dataUrl;
                self.uploadPhoto(dataUrl);
                self.isCameraOpen = false;
                self.stopCameraStream();

            }, FLASH_TIMEOUT);
            this.isCapturing = false;
        },
        cropImage() {
            const { coordinates, canvas, } = this.$refs.cropper.getResult();
            this.coordinates = coordinates;
            this.croppedPic = canvas.toDataURL();
            this.capturedPhotoFile = this.dataURLtoFile(this.croppedPic, Math.random().toString(36).substring(2, 15)+'.jpg')
        },
        resetCroppedImage(){
            this.capturedPhotoFile = null;
            this.croppedPic = null;
        },
        saveData(){
            let fd = '';
            if(this.useWebcam){
                fd = new FormData()
                fd.append('photo', this.capturedPhotoFile)
                if (this.marksheet && this.marksheet != undefined && this.marksheet != null) {
                    fd.append('marksheet', this.marksheet);
                }
                if (this.document_one && this.document_one != undefined && this.document_one != null) {
                    fd.append('file_name[]', this.document_one_title);
                    fd.append('file[]', this.document_one);
                }
                if (this.document_two && this.document_two != undefined && this.document_two != null) {
                    fd.append('file_name[]', this.document_two_title);
                    fd.append('file[]', this.document_two);
                }
                if (this.document_three && this.document_three != undefined && this.document_three != null) {
                    fd.append('file_name[]', this.document_three_title);
                    fd.append('file[]', this.document_three);
                }
            }else{
                fd = this.buildFormData();
            }
            this.loading = true;
            enrollmentService
                .uploadFiles(this.enrollmentId, fd)
                .then(response => {
                  if(this.is_payment_completed){
                    this.markAsPaid();
                  }else{
                    this.displayMessage("success");
                    this.loading = false
                    this.closeDialog();
                  }




                }).catch(error => {
                this.loading = false
                this.displayMessage("error");
            })
            .finally(() => {
                this.loading = false;

            })


        },

        uploadPhoto(dataUrl){
            this.capturedPhotoFile = this.dataURLtoFile(dataUrl, Math.random().toString(36).substring(2, 15)+'.jpg')
        },
        recapture(){
            this.toggleCamera();
            this.previewCapturedPhoto = null;
            this.capturedPhotoFile = null;
            this.croppedPic = null;
        },
        startCameraStream() {
            const constraints = (window.constraints = {
                audio: false,
                video: true
            });
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.$refs.camera.srcObject = stream;
                }).catch(error => {
                // alert("Browser doesn't support or there is some errors." + error);
            });
        },

        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach(track => {
                track.stop();
            });
        },
        buildFormData() {
            let fd = new FormData();
            if (this.photo && this.photo != undefined && this.photo != null) {
                fd.append('photo', this.photo);
            }
            if (this.marksheet && this.marksheet != undefined && this.marksheet != null) {
                fd.append('marksheet', this.marksheet);
            }
            if (this.document_one && this.document_one != undefined && this.document_one != null) {
                fd.append('file_name[]', this.document_one_title);
                fd.append('file[]', this.document_one);
            }
            if (this.document_two && this.document_two != undefined && this.document_two != null) {
                fd.append('file_name[]', this.document_two_title);
                fd.append('file[]', this.document_two);
            }
            if (this.document_three && this.document_three != undefined && this.document_three != null) {
                fd.append('file_name[]', this.document_three_title);
                fd.append('file[]', this.document_three);
            }
            return fd;
        },
        dataURLtoFile(dataURL, filename) {
            let arr = dataURL.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type: mime});
        },
        previewAdmitCard() {
            let url = `${API_URL}user/pre-registration/${this.enrollmentId}/preview/admit-card`
            window.open(url, "_blank")
        },
        previewInvoice() {
            let url = `${API_URL}user/pre-registration/${this.enrollmentId}/preview/invoice`
            window.open(url, "_blank")
        },
        openCropperForFileUpload(){
            this.cropUploadFile = !this.cropUploadFile;
        },
        defaultSize(){
          return {
            width: 160,
            height: 180,
          };
        },
        markAsPaid() {
          if(this.enrollmentId){
            this.loading=true;
            let data={
              payment_method:'offline',
              paid_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
              remarks:this.remarks ?? null,
            };
            enrollmentService
                .markAsPaid(this.enrollmentId,data)
                .then(response => {
                  this.displayMessage("success");
                  this.loading = false
                  this.closeDialog();
                })
                .catch(error => {
                  this.displayMessage("error")
                })
                .finally(() => {
                  this.loading=false;
                });
          }
      },

    }
}
</script>
<style scoped>
.camera-box {
    border: 1px dashed #d6d6d6;
    border-radius: 4px;
    padding: 2px;
    width: 100%;
    min-height: 100px;
}
.captured-img{
    width:30%;
}

.cropper {
    /* height: 600px; */
    width: 100%;
    object-fit: contain;
    background: #DDD;
}


</style>