<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row text-left">
              <div class="col-md-6">
                <h3>
                  Create new application
                </h3>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                Applications
              </div>

            </div>
            <div class="row" v-if="!enrollmentLoading">
              <div class="col-lg-12">
                <v-row>
                  <v-col cols="6">
                    <v-select :items="opened_levels" item-text="display_name" item-value="idx" v-model="level" outlined
                              dense
                              label="Select level" @change="getPreregistrationProgramsByLevel()"></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select :items="opened_programs" item-text="display_name" item-value="idx" v-model="program" outlined dense
                              label="Select program" @change="getPreRegistrationDetail()">
                    </v-select>
                  </v-col>
                </v-row>
                <div v-if="opened_levels.length > 0">
                  <template v-if="pre_registration">
                    <div style="margin-top: 22px;">
                      <v-stepper v-model="step" vertical>
                        <!-- General information Stepper-->
                        <v-stepper-step :complete="step > 1" step="1" :editable="editable==1">
                          General information
                        </v-stepper-step>
                        <v-stepper-content step="1">

                          <v-card class="mb-12" style=" height: fit-content;">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <h4>General information <span class="text-danger">*</span></h4>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field outlined dense v-model="enrolmentDetail.first_name" :error="$v.enrolmentDetail.first_name.$error">
                                    <template v-slot:label>
                                      First name <span class="text-danger">*</span>
                                    </template>
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.enrolmentDetail.first_name.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field outlined dense v-model="enrolmentDetail.middle_name" label="Middle name"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field  outlined dense v-model="enrolmentDetail.last_name" :error="$v.enrolmentDetail.last_name.$error">
                                    <template v-slot:label>
                                      Last name <span class="text-danger">*</span>
                                    </template>
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.enrolmentDetail.last_name.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field outlined dense v-model="enrolmentDetail.email" :error="$v.enrolmentDetail.email.$error || emailValidationFlag">
                                    <template v-slot:label>
                                      Email <span class="text-danger">*</span>
                                    </template>
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.enrolmentDetail.email.$error">
                                  This information is required.
                                </span>
                                  <p class="text-danger" v-if="emailValidationFlag">Email should be valid</p>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field  outlined dense v-model="enrolmentDetail.phone" :error="$v.enrolmentDetail.phone.$error">
                                    <template v-slot:label>
                                      Phone <span class="text-danger">*</span>
                                    </template>
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.enrolmentDetail.phone.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field  outlined dense v-model="enrolmentDetail.mobile">
                                    <template v-slot:label>
                                      Mobile
                                    </template>
                                  </v-text-field>

                                </v-col>
                                  <v-col cols="4" class="text-center">
                                      <v-switch  @click="dob_in_ad = !dob_in_ad" @change="changeDOBFormat"
                                                :label="dob_in_ad ?'DOB type AD':'DOB type BS'">
                                      </v-switch>
                                  </v-col>
                                <v-col cols="4" v-if="dob_in_ad">
                                  <v-menu
                                      v-model="dob_menu"
                                      :close-on-content-click="false"
                                      :nudge-right="40"
                                      transition="scale-transition"
                                      offset-y
                                      min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                          v-model="enrolmentDetail.dob"
                                          prepend-inner-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"  @change="convertAdToBs"
                                          v-on="on" :error="$v.enrolmentDetail.dob.$error"
                                          outlined dense

                                      >
                                        <template v-slot:label>
                                          DOB in AD
                                        </template>
                                      </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="enrolmentDetail.dob"
                                        @input="dob_menu = false;  convertAdToBs();"
                                    ></v-date-picker>
                                  </v-menu>
                                  <span class="text-danger" v-if="$v.enrolmentDetail.dob.$error">
                                    This information is required.
                                  </span>
                                </v-col>
                                <v-col cols="2" v-if="!dob_in_ad">
                                    <v-autocomplete
                                            label="DOB Year"
                                            :items="years"
                                            v-model="dob_bs_year"
                                            :error="$v.enrolmentDetail.dob_bs.$error || $v.dob_bs_year.$error"
                                            item-text="title"
                                            item-value="value"
                                            outlined dense required
                                            hide-details   :loading="nepaliYearsLoading"
                                            @change="buildDateOfBirth">

                                    </v-autocomplete>
                                </v-col>
                                  <v-col md="3" lg="3" cols="3" v-if="!dob_in_ad">
                                      <v-select
                                              label="DOB Month"
                                              :items="months"
                                              v-model="dob_bs_month "
                                              item-text="title"
                                              item-value="value"
                                              :loading="nepaliMonthsLoading"
                                              :error="$v.enrolmentDetail.dob_bs.$error || $v.dob_bs_month.$error"
                                              @change="buildDateOfBirth"
                                              outlined dense required></v-select>
                                  </v-col>
                                  <v-col md="3" lg="3" cols="2" v-if="!dob_in_ad">
                                      <v-select label="DOB Day" :items="days"
                                              v-model="dob_bs_day"
                                              item-text="title"
                                              item-value="value"
                                              :loading="daysLoading"
                                              :error="$v.enrolmentDetail.dob_bs.$error || $v.dob_bs_day.$error"
                                              @change="buildDateOfBirth"
                                              outlined dense required></v-select>

                                  </v-col>

                                <v-col cols="4">
                                  <v-select
                                      :items="genders"
                                      v-model="enrolmentDetail.gender"
                                      label="Gender"
                                      item-text="title" :error="$v.enrolmentDetail.gender.$error"
                                      item-value="value"
                                      outlined dense>
                                    <template v-slot:label>
                                      Gender<span class="text-danger">*</span>
                                    </template>
                                  </v-select>
                                  <span class="text-danger" v-if="$v.enrolmentDetail.gender.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4">
                                  <v-text-field outlined dense v-model="enrolmentDetail.offline_form_id">
                                    <template v-slot:label>
                                      Form no.
                                    </template>
                                  </v-text-field>

                                </v-col>
                                <v-col cols="4">
                                  <v-text-field outlined dense v-model="enrolmentDetail.nationality" :error="$v.enrolmentDetail.nationality.$error">
                                    <template v-slot:label>
                                      Nationality.<span class="text-danger">*</span>
                                    </template>
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.enrolmentDetail.nationality.$error">
                                  This information is required.
                                </span>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <h4>Parents information <span class="text-danger">*</span></h4>
                                  <hr>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field outlined dense v-model="parent_information.father_name" label="Father name" :error="$v.parent_information.father_name.$error">
                                  </v-text-field>
                                    <span class="text-danger" v-if="$v.parent_information.father_name.$error">
                                      This information is required.
                                    </span>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field outlined dense v-model="parent_information.father_contact_number" :error="$v.parent_information.father_contact_number.$error" label="Father's contact number"></v-text-field>
                                    <span class="text-danger" v-if="$v.parent_information.father_contact_number.$error">
                                      This information is required.
                                    </span>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field outlined dense v-model="parent_information.mother_name" label="Mother name" :error="$v.parent_information.mother_name.$error"></v-text-field>
                                    <span class="text-danger" v-if="$v.parent_information.mother_name.$error">
                                      This information is required.
                                    </span>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field outlined dense v-model="parent_information.mother_contact_number" label="Mother's contact number" :error="$v.parent_information.mother_contact_number.$error"></v-text-field>
                                    <span class="text-danger" v-if="$v.parent_information.mother_contact_number.$error">
                                      This information is required.
                                    </span>
                                </v-col>
                                  <v-col cols="6">
                                      <v-text-field outlined dense v-model="parent_information.guardian_name" label="Guardian name" :error="$v.parent_information.guardian_name.$error"></v-text-field>
                                      <span class="text-danger" v-if="$v.parent_information.guardian_name.$error">
                                      This information is required.
                                    </span>
                                  </v-col>
                                  <v-col cols="6">
                                      <v-text-field outlined dense v-model="parent_information.guardian_contact_number" label="Guardian's contact number" :error="$v.parent_information.guardian_contact_number.$error"></v-text-field>
                                      <span class="text-danger" v-if="$v.parent_information.guardian_contact_number.$error">
                                      This information is required.
                                    </span>
                                  </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <h4>Permanent address <span class="text-danger">*</span></h4>
                                  <hr>
                                </v-col>
                                <v-col cols="4" >
                                  <v-select outlined dense v-model="permanent_address.country" :error="$v.permanent_address.country.$error" label="Country" :items="countries" item-text="name" item-value="value">
                                  </v-select>
                                  <span class="text-danger" v-if="$v.permanent_address.country.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="permanent_address.country=='nepal'">
                                  <v-select outlined dense v-model="permanent_address.province" :error="$v.permanent_address.province.$error" label="Province" :items="provinces" item-text="name" item-value="id"   @change="getAllDistrictsByProvince()">
                                  </v-select>
                                  <span class="text-danger" v-if="$v.permanent_address.province.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="permanent_address.country=='nepal'">
                                  <v-autocomplete outlined dense  :loading="districtLoading" v-model="permanent_address.district" :error="$v.permanent_address.district.$error" label="District" :items="districts" item-text="name" item-value="id"  @change="getMunicipalitiesByDistrict()">
                                  </v-autocomplete>
                                  <span class="text-danger" v-if="$v.permanent_address.district.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="permanent_address.country=='nepal'">
                                  <v-autocomplete v-model="permanent_address.city"
                                                  outlined dense
                                                  :items="municipalities" :error="$v.permanent_address.city.$error"
                                                  item-text="name" :loading="municipalityLoading"
                                                  item-value="id"
                                                  label="Local level">
                                  </v-autocomplete>
                                  <span class="text-danger" v-if="$v.permanent_address.city.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="permanent_address.country=='nepal'">
                                  <v-autocomplete
                                      v-model="permanent_address.ward"  :error="$v.permanent_address.ward.$error"
                                      outlined dense
                                      :items="wards"
                                      label="Wards">
                                  </v-autocomplete>
                                  <span class="text-danger" v-if="$v.permanent_address.ward.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col md="4" lg="4" cols="12" v-if="permanent_address.country =='nepal'">
                                  <v-text-field
                                                v-model="permanent_address.area"
                                                outlined dense
                                                label="Area/Tol"
                                  >
                                  </v-text-field>

                                </v-col>
                                <v-col md="4" lg="4" cols="12" v-if="permanent_address.country =='other'">
                                  <v-text-field v-model="permanent_address.other_address" :error="$v.permanent_address.other_address.$error"
                                                label="address" outlined
                                                dense hide-details>
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.permanent_address.other_address.$error">
                                  This information is required.
                                </span>
                                </v-col>
                              </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-checkbox @change="changeTemporaryAddress" v-model="copy_permanent_address">
                                            <template v-slot:label>
                                                <div> Use permanent address as temporary address</div>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <h4>Temporary address <span class="text-danger">*</span></h4>
                                  <hr>
                                </v-col>
                                <v-col cols="4" >
                                  <v-select outlined dense v-model="temporary_address.country" :error="$v.temporary_address.country.$error" label="Country" :items="countries" item-text="name" item-value="value">
                                  </v-select>
                                  <span class="text-danger" v-if="$v.temporary_address.country.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="temporary_address.country=='nepal'">
                                  <v-select outlined dense v-model="temporary_address.province" :error="$v.temporary_address.province.$error" label="Province" :items="provinces" item-text="name" item-value="id"   @change="getAllTemporaryDistrictsByProvince()">
                                  </v-select>
                                  <span class="text-danger" v-if="$v.temporary_address.province.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="temporary_address.country=='nepal'">
                                  <v-autocomplete outlined dense :loading="temporaryDistrictLoading" v-model="temporary_address.district" :error="$v.temporary_address.district.$error" label="District" :items="temporary_districts" item-text="name" item-value="id"  @change="getTemporaryMunicipalitiesByDistrict()">
                                  </v-autocomplete>
                                  <span class="text-danger" v-if="$v.temporary_address.district.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="temporary_address.country=='nepal'">
                                  <v-autocomplete v-model="temporary_address.city"
                                                  outlined dense :loading="temporaryMunicipalityLoading"
                                                  :items="temporary_municipalities" :error="$v.temporary_address.city.$error"
                                                  item-text="name"
                                                  item-value="id"
                                                  label="Local level">
                                  </v-autocomplete>
                                  <span class="text-danger" v-if="$v.temporary_address.city.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="4" v-if="temporary_address.country=='nepal'">
                                  <v-autocomplete
                                      v-model="temporary_address.ward"  :error="$v.temporary_address.ward.$error"
                                      outlined dense
                                      :items="wards"
                                      label="Wards">
                                  </v-autocomplete>
                                  <span class="text-danger" v-if="$v.temporary_address.ward.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col md="4" lg="4" cols="12" v-if="temporary_address.country =='nepal'">
                                  <v-text-field
                                                v-model="temporary_address.area"
                                                outlined dense
                                                label="Area/Tol"
                                  >
                                  </v-text-field>

                                </v-col>
                                <v-col md="4" lg="4" cols="12" v-if="temporary_address.country =='other'">
                                  <v-text-field v-model="temporary_address.other_address" :error="$v.temporary_address.other_address.$error"
                                                label="address" outlined
                                                dense hide-details>
                                  </v-text-field>
                                  <span class="text-danger" v-if="$v.temporary_address.other_address.$error">
                                  This information is required.
                                </span>
                                </v-col>
                              </v-row>

                            </v-card-text>
                            <v-card-actions>
                              <v-divider></v-divider>


                              <v-btn class="text-white ml-2 btn btn-primary"
                                     depressed @click="firstStepButton">Continue</v-btn>
                            </v-card-actions>
                          </v-card>

                        </v-stepper-content>
                        <!-- Educational background Stepper-->
                        <v-stepper-step :complete="step > 2" step="2" :editable="editable==1">
                          Educational background
                        </v-stepper-step>
                        <v-stepper-content step="2">
                          <v-card class="mb-12" style=" height: fit-content;">
                            <v-card-text>
                              <v-row v-if="educational_information.academic_backgrounds.length > 0">
                                <v-col cols="5">
                                  <h4>Educational information</h4>
                                </v-col>
                                <hr>
                                <v-row v-for="(academic, index) in educational_information.academic_backgrounds" :key="index">
                                  <v-col cols="12">
                                    <strong class="text-uppercase"> {{ academic.name }}</strong>
                                  </v-col>
                                  <v-col cols="4">

                                    <v-text-field v-model="academic.institute_name" outlined dense :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].institute_name.$error">
                                      <template v-slot:label>
                                        School name <span class="text-danger">*</span>
                                      </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                      <span v-if="$v.educational_information.academic_backgrounds.$each[index].institute_name.$error">
                                                    This information is required
                                      </span>
                                   </span>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-select v-model="academic.completed_year" :items="completion_years" outlined :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].completed_year.$error"
                                              dense>
                                      <template v-slot:label>
                                        Completion year <span class="text-danger">*</span>
                                      </template>
                                    </v-select>
                                    <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                      <span v-if="$v.educational_information.academic_backgrounds.$each[index].completed_year.$error">
                                                    This information is required
                                      </span>
                                   </span>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-text-field v-model="academic.exam_board" outlined dense :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].exam_board.$error">
                                      <template v-slot:label>
                                        Board/Exams <span class="text-danger">*</span>
                                      </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                      <span v-if="$v.educational_information.academic_backgrounds.$each[index].exam_board.$error">
                                                    This information is required
                                      </span>
                                   </span>
                                  </v-col>
                                  <v-col cols="2">
                                    <v-text-field v-model="academic.gpa" label="GPA" type="number" outlined min="0" max="4"
                                                  dense :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].gpa.$error"
                                                  hide-details>
                                        <template v-slot:label>
                                            GPA <span class="text-danger">*</span>
                                        </template>
                                    </v-text-field>
                                    <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                        <span v-if="$v.educational_information.academic_backgrounds.$each[index].gpa.$error">
                                                      This information is required
                                        </span>
                                    </span>
                                  </v-col>
                                  <v-col md="2" lg="2" cols="12">
                                    <v-text-field v-model="academic.symbol_no"  outlined dense
                                                  hide-details>
                                      <template v-slot:label>
                                        <span class="text-uppercase">{{academic.name}}</span> Symbol No
                                      </template>
                                    </v-text-field>
                                  </v-col>
                                    <hr>
                                    <v-col md="3">
                                        <v-select v-if="academic"  v-model="academic.country"
                                                  :items="countries" :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].country.$error"
                                                  item-text="name" item-value="value" label="Country"
                                                  outlined
                                                  dense hide-details>
                                            <template v-slot:label>
                                                Country <span class="text-danger">*</span>
                                            </template>
                                        </v-select>
                                        <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                          <span v-if="$v.educational_information.academic_backgrounds.$each[index].country.$error">
                                                        This information is required
                                          </span>
                                        </span>
                                    </v-col>
                                    <v-col md="3" v-if="academic && academic.country=='nepal'">
                                        <v-select outlined dense v-model="academic.province"  label="Province" :items="provinces" item-text="name" item-value="id"
                                                  :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].province.$error"
                                                  @change="getEducationalDistrictByProvince(academic.province,index)">
                                            <template v-slot:label>
                                                Province <span class="text-danger">*</span>
                                            </template>
                                        </v-select>
                                        <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                          <span v-if="$v.educational_information.academic_backgrounds.$each[index].province.$error">
                                                        This information is required
                                          </span>
                                        </span>
                                    </v-col>
                                    <v-col md="3" v-if="academic && academic.country=='nepal'">
                                        <v-autocomplete outlined dense  v-model="academic.district"  label="District" :items="getEducationalDistricts(academic.province)" item-text="name" item-value="id"
                                                        :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].district.$error" @change="getEducationalMunicipalitiesByDistrict(academic.district,index)">
                                            <template v-slot:label>
                                                District <span class="text-danger">*</span>
                                            </template>
                                        </v-autocomplete>
                                        <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                          <span v-if="$v.educational_information.academic_backgrounds.$each[index].district.$error">
                                                        This information is required
                                          </span>
                                        </span>
                                    </v-col>
                                    <v-col md="3" v-if="academic && academic.country=='nepal'">
                                        <v-autocomplete v-model="academic.city"
                                                        outlined dense
                                                        :items="getEducationalMunicipalities(academic.district)"
                                                        item-text="name"
                                                        item-value="id"
                                                        label="Local level">
                                            <template v-slot:label>
                                                Local level
                                            </template>
                                        </v-autocomplete>

                                    </v-col>
                                    <v-col md="3" v-if="academic && academic.country=='nepal'">
                                        <v-autocomplete
                                                v-model="academic.ward"
                                                outlined dense
                                                :items="wards"
                                                label="Wards">
                                            <template v-slot:label>
                                                Wards
                                            </template>
                                        </v-autocomplete>

                                    </v-col>
                                    <v-col md="3" v-if="academic && academic.country=='nepal'">
                                        <v-text-field
                                                v-model="academic.area"
                                                outlined dense
                                                label="Area/Tol">
                                            <template v-slot:label>
                                                Area/Tol
                                            </template>
                                        </v-text-field>

                                    </v-col>
                                    <v-col md="3" v-if="academic && academic.country=='other'">
                                        <v-text-field v-model="academic.other_address"
                                                      outlined dense :error="educational_information.academic_backgrounds.length > 0 && $v.educational_information.academic_backgrounds.$each[index].other_address.$error"
                                                      label="Other address">
                                            <template v-slot:label>
                                                Other address <span class="text-danger">*</span>
                                            </template>
                                        </v-text-field>
                                        <span class="text-danger" v-if="educational_information.academic_backgrounds.length > 0">
                                          <span v-if="$v.educational_information.academic_backgrounds.$each[index].other_address.$error">
                                                        This information is required
                                          </span>
                                        </span>
                                    </v-col>


                                </v-row>
                              </v-row>

                              <v-row>
                                <v-col cols="12" v-if="pre_registration && pre_registration.compulsory_courses.length>0">

                                  <hr>
                                  <div class="my-2">
                                      <h6>Compulsory subjects</h6>
                                      <p>These are subjects that you will study in this program.</p>
                                  </div>
                                  <div class=" justify-content-around">
                                  <span class="badge badge-course ml-8 mr-8" v-for="(course, index) of pre_registration.compulsory_courses" :key="index">
                                              {{ course }}
                                    </span>
                                  </div>
                                </v-col>
                                <v-col cols="12">
                                    <div class="">
                                        <h5> Optional subjects</h5>
                                        <p>Select optional subjects that you would like to study.</p>
                                    </div>
                                  <div class="ml-2" v-if="pre_registration && pre_registration.enable_selectable_course_1">
                                      <strong> Choose any ONE <span class="text-danger">*</span></strong><hr>
                                      <v-radio-group v-model="educational_information.selectable_course_1"
                                                   hide-details row>
                                      <v-radio :value="course" v-for="(course, index) of pre_registration.selectable_course_1" :key="index">
                                        <template v-slot:label>
                                          <span class="text-capitalize font-weight-medium">{{ course }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                    <hr>
                                  </div>
                                  <span class="text-danger" v-if="pre_registration && pre_registration.enable_selectable_course_1 && $v.educational_information.selectable_course_1.$error">
                                  This information is required.
                                </span>

                                </v-col>
                                <v-col cols="12">
                                  <div class="ml-2" v-if="pre_registration && pre_registration.enable_selectable_course_2">
                                      <strong> Choose any ONE <span class="text-danger">*</span></strong><br>
                                    <hr>
                                    <v-radio-group v-model="educational_information.selectable_course_2"
                                                   hide-details row>
                                      <v-radio :value="course"
                                               v-for="(course, index) of pre_registration.selectable_course_2" :key="index"
                                      >
                                        <template v-slot:label>
                                          <span class="text-capitalize font-weight-medium">{{ course }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                    <hr>

                                  </div>
                                  <span class="text-danger" v-if="pre_registration && pre_registration.enable_selectable_course_2 && $v.educational_information.selectable_course_2.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="12">
                                  <div class="ml-2"
                                       v-if="pre_registration && pre_registration.enable_selectable_course_3">
                                      <strong> Choose any ONE <span class="text-danger">*</span></strong><br>
                                    <hr>
                                    <v-radio-group v-model="educational_information.selectable_course_3"
                                                   hide-details row>
                                      <v-radio :value="course"
                                               v-for="(course, index) of pre_registration.selectable_course_3" :key="index"
                                      >
                                        <template v-slot:label>
                                          <span class="text-capitalize font-weight-medium">{{ course }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                    <hr>

                                  </div>
                                  <span class="text-danger" v-if="pre_registration && pre_registration.enable_selectable_course_3 && $v.educational_information.selectable_course_3.$error">
                                      This information is required.
                                    </span>

                                </v-col>
                                <v-col cols="12">
                                  <div class="ml-5"
                                       v-if="pre_registration &&  pre_registration.enable_selectable_course_4">
                                      <strong> Choose any ONE <span class="text-danger">*</span></strong><br>
                                    <hr>
                                    <v-radio-group v-model="educational_information.selectable_course_4"
                                                   hide-details row>
                                      <v-radio :value="course"
                                               v-for="(course, index) of pre_registration.selectable_course_4" :key="index"
                                      >
                                        <template v-slot:label>
                                          <span class="text-capitalize font-weight-medium">{{ course }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                    <hr>
                                  </div>
                                  <span class="text-danger" v-if="pre_registration && pre_registration.enable_selectable_course_4 && $v.educational_information.selectable_course_4.$error">
                                  This information is required.
                                </span>
                                </v-col>
                                <v-col cols="12">
                                  <div class="ml-2" v-if="pre_registration &&  pre_registration.shifts.length>0">
                                    <h6>Which class timing would you prefer?</h6>
                                    <hr>
                                    <v-radio-group v-model="educational_information.shift" hide-details row>
                                      <v-radio :value="shift" v-for="(shift, index) of pre_registration.shifts" :key="index">
                                        <template v-slot:label>
                                          <div class="text-capitalize">{{ shift }}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                    <hr>
                                  </div>
                                  <span class="text-danger" v-if="pre_registration && pre_registration.shifts && pre_registration.shifts.length > 0 && $v.educational_information.shift.$error">
                                  This information is required.
                                </span>
                                </v-col>

                                <v-col cols="12" >
                                  <h6>Select an entrance exam</h6>
                                  <div v-if="entrance_dates.length > 0">
                                    <div class="ml-2" v-for="(entrance,en) in entrance_dates"
                                         :key="en">
                                      <span class="font-size-lg font-weight-bold">{{entrance_dates[en][0].date}}</span>

                                      <v-radio-group
                                          v-model="educational_information.entrance_date"
                                          hide-details row>
                                        <v-radio v-for="(ent,ei) in entrance"
                                                 :value="ent.idx" :key="ei">
                                          <template v-slot:label>
                                            <div class="text-capitalize">{{ ent.display_text }}</div>
                                          </template>
                                        </v-radio>

                                      </v-radio-group>
                                      <hr>
                                    </div>
                                  </div>
                                  <span class="text-danger" v-if="$v.educational_information.entrance_date.$error">
                                  This information is required.
                                </span>
                                </v-col>

                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-divider></v-divider>
                              <v-btn text>Cancel</v-btn>

                              <v-btn class="text-white ml-2 btn btn-primary"
                                     depressed @click="secondStepButton">Continue</v-btn>
                            </v-card-actions>
                          </v-card>

                        </v-stepper-content>
                        <!-- Awards & ECA Stepper -->
                        <v-stepper-step :complete="step > 3" step="3" :editable="editable==1" v-if="extra_information.enable_award_eca">Awards & ECA</v-stepper-step>
                        <v-stepper-content step="3" v-if="extra_information.enable_award_eca">
                          <v-card  class="mb-12" style=" height: fit-content;">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" v-if="extra_information.award_and_eca.enabled_received_awards_certificates">
                                  <span class="title font-weight-bold">Rewards and certificates</span><br>
                                  <v-textarea dense outlined v-model="additional_details.awards_and_eca.rewards_and_certificates" label="Please list all rewards and certificates received."></v-textarea>
                                </v-col>
                                <v-col cols="12" v-if="extra_information.award_and_eca.enabled_involvement_in_club_organizations">
                                  <span class="title font-weight-bold">Mention clubs,organizations,sports and/or extracurricular activities in which you have been involved.</span><br>
                                  <v-textarea dense outlined v-model="additional_details.awards_and_eca.club_involvement" label="Please indicate how long you were or have been involved in each and describe your participation.">
                                  </v-textarea>
                                </v-col>
                                <v-col cols="12" v-if="extra_information.award_and_eca.enabled_volunteered_organization">
                                  <v-row>
                                    <v-col cols="8">
                                      <span class="title font-weight-bold">Have you ever volunteered for an organization or done community service?</span><br>
                                    </v-col>
                                    <v-col cols="4" >
                                      <v-radio-group v-model="additional_details.awards_and_eca.has_volunteered_organization" row>
                                        <v-radio
                                            label="Yes"
                                            :value='true'
                                        ></v-radio>
                                        <v-radio
                                            label="No"
                                            :value="false"
                                        ></v-radio>
                                      </v-radio-group>
                                    </v-col>
                                  </v-row>
                                  <div v-if="additional_details.awards_and_eca.has_volunteered_organization">
                                    <v-textarea
                                        :error="$v.additional_details.awards_and_eca.volunteered_organization_text.$error" v-model="additional_details.awards_and_eca.volunteered_organization_text" dense outlined
                                        label="If yes, please list organizations,indicate how long you were or have been involved in each,adn describe your participation.">
                                    </v-textarea>
                                    <span class="text-danger" v-if="$v.additional_details.awards_and_eca.volunteered_organization_text.$error">
                                   This information is required.
                                   </span>
                                  </div>

                                </v-col>
                                <v-col cols="12"  v-if="extra_information.award_and_eca.enabled_future_plans">
                                  <span class="title font-weight-bold">What are your future plans - college or university plans and career goals?</span><br>
                                  <v-textarea dense outlined v-model="additional_details.awards_and_eca.future_plans_text">
                                  </v-textarea>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>

                              <v-divider></v-divider>
                              <v-btn text>Cancel</v-btn>

                              <v-btn class="text-white ml-2 btn btn-primary"
                                     depressed @click="thirdStepButton">Continue</v-btn>
                            </v-card-actions>
                          </v-card>

                        </v-stepper-content>
                        <!-- Additional information -->
                        <v-stepper-step step="4"  :editable="editable==1">
                          Additional information
                        </v-stepper-step>
                        <v-stepper-content step="4">
                          <v-card  class="mb-12" style="height: fit-content;">
                            <v-card-text>
                              <v-row>

                                <v-col cols="4" v-if="pre_registration && pre_registration.enable_hostel_availability">
                                  <span class="title font-weight-medium">Hostel facility</span>
                                </v-col>
                                <v-col cols="8" v-if="pre_registration && pre_registration.enable_hostel_availability">
                                  <v-radio-group v-model="other_information.hostel_facility"
                                                 row
                                                 hide-details>
                                    <v-radio :value="true">
                                      <template v-slot:label>
                                        <div>Yes</div>
                                      </template>
                                    </v-radio>
                                    <v-radio :value="false">
                                      <template v-slot:label>
                                        <div>No</div>
                                      </template>
                                    </v-radio>
                                  </v-radio-group>
                                </v-col>
                                <v-col cols="12" v-if="pre_registration && pre_registration.enable_transportation_facility">
                                  <span class="title font-weight-medium">  Transportation facility </span>
                                </v-col>
                                <div v-if="pre_registration && pre_registration.enable_transportation_facility">
                                  <v-col cols="6" >
                                    <v-radio-group v-model="other_information.transportation_facility"
                                                   row hide-details>
                                      <v-radio :value="true">
                                        <template v-slot:label>
                                          <div>Yes</div>
                                        </template>
                                      </v-radio>
                                      <v-radio :value="false">
                                        <template v-slot:label>
                                          <div>No</div>
                                        </template>
                                      </v-radio>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12" v-if="other_information.transportation_facility && other_information.transportation_facility ">
                                    <v-text-field label="Pickup point" outlined dense :error="$v.other_information.pickup_location.$error"
                                                  v-model="other_information.pickup_location"></v-text-field>
                                    <span class="text-danger" v-if="$v.other_information.pickup_location.$error">This information is required.</span>
                                  </v-col>
                                </div>
                                <v-col cols="12">
                                  <span class="title font-weight-medium"> How did you come to know about  college ? </span>
                                </v-col>
                                <v-col cols="12">
                                  <v-row v-if="pre_registration && pre_registration.recommended_options && pre_registration.recommended_options.length>0">
                                    <v-col cols="6" v-for="(option, index) of pre_registration.recommended_options" :key="index">
                                      <v-checkbox :value="option" v-model="other_information.recomended_sources" :label="option"
                                                  hide-details>
                                      </v-checkbox>

                                    </v-col>
                                    <span class="text-danger" v-if="$v.other_information.recomended_sources.$error"> This information is required.</span>
                                  </v-row>
                                </v-col>
                                <div v-if="extra_information.enable_additional_information">
                                  <hr>
                                  <v-col cols="12" v-if="extra_information.additional_information.enabled_applicant_repeated_class">
                                    <v-row>
                                      <v-col cols="8">
                                        <span class="title font-weight-bold">Has the applicant repeated any class or grade or level ?</span><br>
                                      </v-col>
                                      <v-col cols="4" >
                                        <v-radio-group v-model="additional_details.additional_information.has_applicant_repeated_class" row>
                                          <v-radio
                                              label="Yes"
                                              :value='true'
                                          ></v-radio>
                                          <v-radio
                                              label="No"
                                              :value="false"
                                          ></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                    <div v-if="additional_details.additional_information.has_applicant_repeated_class">
                                      <v-textarea v-model="additional_details.additional_information.applicant_repeated_class_text"
                                                  :error="$v.additional_details.additional_information.applicant_repeated_class_text.$error"  dense outlined
                                                  label="If yes,please specify class or grade or level and reason(s) for repeating">
                                      </v-textarea>
                                      <span class="text-danger" v-if="$v.additional_details.additional_information.applicant_repeated_class_text.$error">
                                      This information is required.
                                    </span>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" v-if="extra_information.additional_information.enabled_applicant_suspended">
                                    <v-row>
                                      <v-col cols="8">
                                        <span class="title font-weight-bold">Has the applicant ever been suspended or dismissed from school/college for any reason?</span><br>
                                      </v-col>
                                      <v-col cols="4" >
                                        <v-radio-group v-model="additional_details.additional_information.has_applicant_suspended" row>
                                          <v-radio
                                              label="Yes"
                                              :value='true'
                                          ></v-radio>
                                          <v-radio
                                              label="No"
                                              :value="false"
                                          ></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                    <div v-if="additional_details.additional_information.has_applicant_suspended">
                                      <v-textarea v-model="additional_details.additional_information.applicant_suspended_text" :error="$v.additional_details.additional_information.applicant_suspended_text.$error" dense outlined label="If yes, please provide specific details:">
                                      </v-textarea>
                                      <span class="text-danger" v-if="$v.additional_details.additional_information.applicant_suspended_text.$error">
                                      This information is required.
                                    </span>
                                    </div>

                                  </v-col>
                                  <v-col cols="12" v-if="extra_information.additional_information.enabled_applicant_have_any_health_issue">
                                    <v-row>
                                      <v-col cols="8">
                                        <span class="title font-weight-bold">Does the applicant have any mental, psychological or physical health condition or disability about which the college should be aware?</span><br>
                                      </v-col>
                                      <v-col cols="4" >
                                        <v-radio-group v-model="additional_details.additional_information.does_applicant_have_mental" row>
                                          <v-radio
                                              label="Yes"
                                              :value='true'
                                          ></v-radio>
                                          <v-radio
                                              label="No"
                                              :value="false"
                                          ></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                    <div v-if="additional_details.additional_information.does_applicant_have_mental">
                                      <v-textarea
                                          :error="$v.additional_details.additional_information.applicant_have_mental_text.$error"
                                          v-model="additional_details.additional_information.applicant_have_mental_text" dense outlined label="If yes, please provide specific details:">
                                      </v-textarea>
                                      <span class="text-danger" v-if="$v.additional_details.additional_information.applicant_have_mental_text.$error">
                                      This information is required.
                                    </span>
                                    </div>

                                  </v-col>
                                  <v-col cols="12" v-if="extra_information.additional_information.enabled_applicant_take_medication">

                                    <v-row>
                                      <v-col cols="8">
                                        <span class="title font-weight-bold">Does the applicant routinely take medication?</span><br>
                                      </v-col>
                                      <v-col cols="4" >
                                        <v-radio-group v-model="additional_details.additional_information.does_applicant_take_medication" row>
                                          <v-radio
                                              label="Yes"
                                              :value='true'
                                          ></v-radio>
                                          <v-radio
                                              label="No"
                                              :value="false"
                                          ></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                    <div v-if="additional_details.additional_information.does_applicant_take_medication">
                                      <v-textarea  v-model="additional_details.additional_information.applicant_take_medication_text" :error="$v.additional_details.additional_information.applicant_take_medication_text.$error" dense outlined label="If yes , please indicate medication(s), dosage, reasons for the medication(s)">
                                      </v-textarea>
                                      <span class="text-danger" v-if="$v.additional_details.additional_information.applicant_take_medication_text.$error">
                                      This information is required.
                                    </span>
                                    </div>

                                  </v-col>
                                  <v-col cols="12" v-if="extra_information.additional_information.enabled_applicant_need_special_needs">

                                    <v-row>
                                      <v-col cols="8">
                                        <span class="title font-weight-bold">Does the applicant have special needs?</span><br>
                                      </v-col>
                                      <v-col cols="4" >
                                        <v-radio-group v-model="additional_details.additional_information.does_applicant_special_needs" row>
                                          <v-radio
                                              label="Yes"
                                              :value='true'
                                          ></v-radio>
                                          <v-radio
                                              label="No"
                                              :value="false"
                                          ></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                    <div v-if="additional_details.additional_information.does_applicant_special_needs">
                                      <v-textarea  v-model="additional_details.additional_information.applicant_special_needs_text"
                                                   :error="$v.additional_details.additional_information.applicant_special_needs_text.$error" dense outlined
                                                   label="Please outline any special needs the student may have which will enable the college to support him or her:">
                                      </v-textarea>
                                      <span class="text-danger" v-if="$v.additional_details.additional_information.applicant_special_needs_text.$error">
                                      This information is required.
                                    </span>
                                    </div>
                                  </v-col>

                                </div>
                                  <v-col md="12">
                                      <span class="title font-weight-medium">Mark as paid ?</span>
                                      <v-list one-line subheader>

                                          <v-list-item>
                                              <v-list-item-action>
                                                  <v-checkbox v-model="mark_as_paid"></v-checkbox>
                                              </v-list-item-action>
                                              <v-list-item-content>
                                                  <v-list-item-title>
                                                      <span class="font-weight-medium text-primary">Check this box to indicate if application fee has been paid.</span>
                                                  </v-list-item-title>

                                              </v-list-item-content>
                                          </v-list-item>


                                      </v-list>
                                  </v-col>
                                  <v-col md="8" v-if="mark_as_paid">
                                      <v-textarea dense outlined v-model="remarks" label="Remarks"> </v-textarea>
                                  </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-divider></v-divider>
                              <v-btn text>Cancel</v-btn>
                              <v-btn class="text-white ml-2 btn btn-primary" :loading="saveLoading"
                                     depressed @click="saveForm">Save</v-btn>
                            </v-card-actions>
                          </v-card>

                        </v-stepper-content>
                      </v-stepper>
                    </div>

                  </template>
                  <template v-if="pre_registration_loading">
                    <div style="margin-top: 22px;">
                      <v-skeleton-loader
                          v-bind="attrs"
                          type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                      ></v-skeleton-loader>

                      <v-skeleton-loader
                          v-bind="attrs"
                          type="list-item-avatar-three-line, image, article"
                      ></v-skeleton-loader>

                    </div>

                  </template>
                </div>
                <div style="margin-top: 25px" v-if="opened_levels.length == 0">
                  <v-alert
                      outlined
                      type="warning"
                      prominent
                      border="left"
                  >
                   <span class="font-weight-bold">
                      There are no pre-registration settings available. Please add them from the <a @click="goToProgramPage()">Program</a> settings.
                  </span>
                  </v-alert>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <upload-document-and-photo ref="document-and-photo"></upload-document-and-photo>
    </div>
  </v-app>
</template>
<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import PreRegistrationSettingService from "@/core/services/level/program/pre-registration-setting/PreRegistrationSettingService";
import {email, required,requiredIf} from "vuelidate/lib/validators";
import UserEnrollmentEssentialService from "@/core/services/user/UserEnrollmentEssentialService";
import EntranceExamService from "@/core/services/entrance-exam/EntranceExamService";
import UploadDocumentAndPhoto from "@/view/pages/pre-registration/UploadDocumentAndPhoto.vue";

const entranceExam=new EntranceExamService();
const isPhone = (value) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);
const preSettingService = new PreRegistrationSettingService();
const enrollmentService = new UserEnrollmentService();
const essentialService = new UserEnrollmentEssentialService();
export default {
    components:{UploadDocumentAndPhoto},
  validations() {
    return {
        dob_bs_year: {
          required: requiredIf(function() {
            return !this.dob_in_ad
          })
        },
        dob_bs_month:{
          required: requiredIf(function() {
            return !this.dob_in_ad
          })
        },
        dob_bs_day:{
          required: requiredIf(function() {
            return !this.dob_in_ad
          })
        },
        enrolmentDetail: this.basic_info_rule,
        permanent_address: this.permanent_address_rule,
        parent_information: this.parent_information_rule,
        temporary_address: this.temporary_address_rule,
        educational_information:this.educational_information_rule,
        additional_details:{
        awards_and_eca:this.awards_and_eca_rule,
        additional_information:this.additional_information_rule,
      },
      other_information:this.miscellany_rule,
    }

  },
  data(){
    return{
        emailValidationFlag:false,
        mark_as_paid:false,
        copy_permanent_address:false,
        nepaliYearsLoading:false,
        nepaliMonthsLoading:false,
        daysLoading:false,
        saveLoading:false,

      dob_in_ad: false,
      pre_registration_loading:false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      inline:1,
      editable:1,
      step:1,
      enrollmentLoading:false,
      levelLoading:false,
      programLoading:false,
      genders: [
        {title: "Male", "value": "male"},
        {title: "Female", "value": "female"}
      ],
      enrolmentDetail: {
        user_id:null,
        first_name:null,
        middle_name:null,
        last_name:null,
        email:null,
        phone:null,
        mobile:null,
        dob:null,
        dob_bs:null,
        gender:null,
        nationality:'Nepali',
        dob_type:false,
        offline_form_id:null,
        entrance_exam_id:null,
      },
      user_enrolment_info:null,
      educational_information:{
        academic_backgrounds:[],
        selectable_course_1:null,
        selectable_course_2:null,
        selectable_course_3:null,
        selectable_course_4:null,
        shift:null,
        entrance_date:null,
        entrance_date_time:null,
      },
      opened_levels:[],
      opened_programs:[],
      level:null,
      program:null,
      dob_menu:false,

      pre_registration:null,
      other_information:{
        hostel_facility:false,
        transportation_facility:false,
        pickup_location:null,
        recomended_sources:[],
      },
      additional_documents: [
        {
          file_name: null, file: null
        }
      ],
      extra_information:{
        enable_award_eca:false,
        award_and_eca:{
          enabled_received_awards_certificates:false,
          enabled_involvement_in_club_organizations:false,
          enabled_volunteered_organization:false,
          enabled_future_plans:false
        },
        enable_additional_information:false,
        additional_information:{
          enabled_applicant_repeated_class:false,
          enabled_applicant_suspended:false,
          enabled_applicant_have_any_health_issue:false,
          enabled_applicant_take_medication:false,
          enabled_applicant_need_special_needs:false,
        }
      },
      additional_details:{
        awards_and_eca:{
          rewards_and_certificates:null,
          club_involvement:null,
          has_volunteered_organization:false,
          volunteered_organization_text:null,
          future_plans_text:null,
          have_awarded_scholarship:false,
          scholarship_text:null,
        },
        additional_information:{
          has_applicant_repeated_class:false,
          applicant_repeated_class_text:null,
          has_applicant_suspended:false,
          applicant_suspended_text:null,
          does_applicant_have_mental:false,
          applicant_have_mental_text:null,
          does_applicant_take_medication:false,
          applicant_take_medication_text:null,
          does_applicant_special_needs:false,
          applicant_special_needs_text:null,
        },
      },
      parent_information:{
        father_name:null,
        father_contact_number:null,
        mother_name:null,
        mother_contact_number:null,
        guardian_name:null,
        guardian_contact_number:null,
      },
      countries: [
        {name: 'Nepal', value: 'nepal'},
        {name: 'Other', value: 'other'},
      ],
      permanent_address:{
        country:'nepal',
        province:null,
        district:null,
        city:null,
        ward:null,
        area:null,
        other_address:null,
      },
        temporary_address:{
            country:'nepal',
            province:null,
            district:null,
            city:null,
            ward:null,
            area:null,
            other_address:null,
        },
      municipalities:[],
      temporary_municipalities:[],
      districts:[],
      temporary_districts:[],
      provinces:[],
      entrance_dates:[],
      years:[],
      months:[],
        days:[],
      dob_bs_year:'',
      dob_bs_month:'',
      dob_bs_day:'',
      temporaryDistrictLoading:false,
      districtLoading:false,
      municipalityLoading:false,
      temporaryMunicipalityLoading:false,
     educational_districts:[],
     educational_municipalities:[],
     remarks:null,
    }
  },
  methods:{
    //Get Level
    getPreregistrationLevel() {
      this.levelLoading=true;
      preSettingService
          .getOpenedAdmission()
          .then(response => {
            this.opened_levels = response.data.programs
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.levelLoading = false;
          });
    },
    //Call Program
    getPreregistrationProgramsByLevel() {
      this.programLoading=true;
      preSettingService
          .getPreregistrationProgramsByLevel(this.level)
          .then((response) => {
            this.opened_programs = response.data
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {
            this.programLoading = false;
          });
    },
    //Call PreRegistration Detail when Program is selected
    getPreRegistrationDetail(){
      this.pre_registration_loading=true;
      this.pre_registration =null;
      preSettingService
          .preRegistrationByProgram(this.program)
          .then(response => {
            this.pre_registration = response.data.setting
            if(this.pre_registration){
                this.getNepaliYears();
                this.getNepaliMonth();
                this.getDays();
              this.getAllEntranceExam();
              //building empty object when program or level is changed,
              this.buildObject(this.pre_registration);
              if(this.pre_registration.extra_information){
                this.extra_information=JSON.parse(this.pre_registration.extra_information);
              }
            }
          })
          .catch((err) => {

          })
          .finally(() => {
            this.pre_registration_loading=false;
          });
    },
    //building empty object when program or level is changed,
    buildObject(pre_registration) {
      this.enrolmentDetail={
        user_id:null,
        first_name:null,
        middle_name:null,
        last_name:null,
        email:null,
        phone:null,
        mobile:null,
        dob:null,
        dob_bs:null,
        gender:null,
        offline_form_id:null,
        nationality:'Nepali',
      };

      this.additional_details = {
        awards_and_eca:{
          rewards_and_certificates:null,
          club_involvement:null,
          has_volunteered_organization:false,
          volunteered_organization_text:null,
          future_plans_text:null,
          have_awarded_scholarship:false,
          scholarship_text:null,
        },
        additional_information:{
          has_applicant_repeated_class:false,
          applicant_repeated_class_text:null,
          has_applicant_suspended:false,
          applicant_suspended_text:null,
          does_applicant_have_mental:false,
          applicant_have_mental_text:null,
          does_applicant_take_medication:false,
          applicant_take_medication_text:null,
          does_applicant_special_needs:false,
          applicant_special_needs_text:null,
        },
      };

      this.other_information={
        hostel_facility:false,
        transportation_facility:false,
        pickup_location:null,
        recomended_sources:[],
      };
      this.educational_information={
        academic_backgrounds:[],
        selectable_course_1:null,
        selectable_course_2:null,
        selectable_course_3:null,
        selectable_course_4:null,
        shift:null,
      };

      if(pre_registration.academic_backgrounds && pre_registration.academic_backgrounds.length > 0) {
        let data=[];
        for (let name of pre_registration.academic_backgrounds) {
          data.push({
            name: name,
            institute_name: null,
            completed_year: null,
            exam_board: null,
            gpa: null,
            country: 'nepal',
              province:null,
              district:null,
              city:null,
              ward:null,
              area:null,
              other_address:null,
          })
        }
        this.educational_information.academic_backgrounds=data;
        this.parent_information={
              father_name:null,
              father_contact_number:null,
              mother_name:null,
              mother_contact_number:null,
        };

      }
    },
    cancel(){

    },
    removeAdditionalDocument(index) {
      this.additional_documents.splice(index, 1)
    },
    add_additional_documents(max_size) {
      max_size = parseInt(max_size)
      if (this.additional_documents.length == (max_size)) {
        this.$snotify.error("Max certificate limit reached")
      } else {
        this.additional_documents.push({
          file_name: null, file: null
        })
      }
    },
    //"Continue"  First button
    firstStepButton(){
      this.$v.enrolmentDetail.$touch();
      this.$v.permanent_address.$touch();
      this.$v.temporary_address.$touch();
        this.$v.parent_information.$touch();
        this.$v.dob_bs_year.$touch();
        this.$v.dob_bs_month.$touch();
        this.$v.dob_bs_day.$touch();

      if (this.$v.enrolmentDetail.$error || this.$v.parent_information.$error || this.$v.permanent_address.$error || this.$v.temporary_address.$error
          || this.$v.dob_bs_year.$error || this.$v.dob_bs_month.$error || this.$v.dob_bs_day.$error) {
        setTimeout(() => {
          this.$v.enrolmentDetail.$reset();
          this.$v.permanent_address.$reset();
          this.$v.temporary_address.$reset();
          this.$v.parent_information.$reset();
          this.$v.dob_bs_year.$reset();
          this.$v.dob_bs_month.$reset();
          this.$v.dob_bs_day.$reset();
        }, 15000);
      } else {
        this.step=2;
        this.getAllEntranceExam();
      }
    },
    //"Continue"  Second button
    secondStepButton(){
      this.$v.educational_information.$touch();
      if (this.$v.educational_information.$error) {

        setTimeout(() => {
          this.$v.educational_information.$reset();
        }, 15000);
      } else {
        this.step=this.extra_information.enable_award_eca ? 3 : 4;

      }
    },
    //"Continue"  Third button
    thirdStepButton(){
      this.$v.additional_details.awards_and_eca.$touch();
      if (this.$v.additional_details.awards_and_eca.$error) {
        setTimeout(() => {
          this.$v.additional_details.awards_and_eca.$reset();
        }, 15000);
      } else {
        this.step=4;
      }
    },

    customizeDataToCreateNew(){
      //customizing like old data from database.
      let data={};
      if(this.pre_registration && this.pre_registration.id){
        data.pre_registration_id=this.pre_registration.id;
      }
      let more_details={};
      if(this.enrolmentDetail){
        data.user_id=this.enrolmentDetail.user_id;
        data.first_name=this.enrolmentDetail.first_name;
        data.offline_form_id=this.enrolmentDetail.offline_form_id;
        data.middle_name=this.enrolmentDetail.middle_name;
        data.last_name=this.enrolmentDetail.last_name;
        data.email=this.enrolmentDetail.email;
        data.phone=this.enrolmentDetail.phone;
        data.mobile=this.enrolmentDetail.mobile;
        data.dob=this.enrolmentDetail.dob;
        data.dob_bs=this.enrolmentDetail.dob_bs;
        data.gender=this.enrolmentDetail.gender;
        data.nationality=this.enrolmentDetail.nationality;
        data.additional_details=JSON.stringify(this.additional_details);
        if(this.educational_information.entrance_date){
          data.entrance_exam_id=this.educational_information.entrance_date;
        }
        //customizing basic information
        more_details.basic_info={
          full_name:this.enrolmentDetail.first_name+''+this.enrolmentDetail.middle_name+''+this.enrolmentDetail.last_name,
          email:data.email,
          phone:data.phone,
          nationality:data.nationality,
          dob:data.dob,
          dob_bs:data.dob_bs,
          gender:this.enrolmentDetail.gender,
          father_name:this.parent_information.father_name,
          father_contact_number:this.parent_information.father_contact_number,
          guardian_name:this.parent_information.guardian_name,
          guardian_contact_number:this.parent_information.guardian_contact_number,
          mother_name:this.parent_information.mother_name,
          mother_contact_number:this.parent_information.mother_contact_number,
          country:this.permanent_address.country,
          province:this.permanent_address.province,
          district:this.permanent_address.district,
          city:this.permanent_address.city,
          ward:this.permanent_address.ward,
          area:this.permanent_address.area,
          other_address:this.permanent_address.other_address,
          temporary_country:this.temporary_address.country,
          temporary_province:this.temporary_address.province,
          temporary_district:this.temporary_address.district,
          temporary_city:this.temporary_address.city,
          temporary_ward:this.temporary_address.ward,
          temporary_area:this.temporary_address.area,
          temporary_other_address:this.temporary_address.other_address,
        }
      }


      if(this.educational_information.entrance_date){
        this.educational_information.entrance_date_time=this.educational_information.entrance_date;
      }
    /*  delete this.educational_information.entrance_date;*/
      more_details.educational_information=this.educational_information;

      more_details.other_information={
        hostel_facility:this.other_information.hostel_facility,
        transportation_facility:this.other_information.transportation_facility,
        pickup_location:this.other_information.pickup_location,
        recomended_sources:this.other_information.recomended_sources,
        photo:null,
        marksheet:null,
        declaration:this.declaration,
      }
      data.more_details=JSON.stringify(more_details);
      if(this.level){
        data.level_id=this.level;
      }
      if(this.program){
        data.program_id=this.program;
      }
      this.user_enrolment_info=data;
    },
    saveForm(){
      if(this.pre_registration && this.pre_registration.id) {
        this.validateWhileStep();

        if (!this.$v.$error) {
          this.customizeDataToCreateNew();
          this.saveLoading=true;
          enrollmentService
              .saveUserEnrolmentInfo(this.user_enrolment_info)
              .then(response => {
                if(response.data.status=='VALIDATION_ERROR'){
                  this.step=1;
                  this.emailValidationFlag=true;
                  setTimeout(() => {
                    this.emailValidationFlag=false;
                  }, 15000);
                }else{
                  this.$snotify.success("Application has been created successfully");
                  if(response.data.enrollment){
                    this.openUploadDocumentAndPhotoDialog(response.data.enrollment,this.mark_as_paid);
                  }else{
                    this.$router.push({
                      name: 'pre-registration-list',
                    });
                    this.$tabs.close();
                  }
                }
              })
              .catch((err) => {
                  this.saveLoading=false;
                 this.$snotify.error("Oops something went wrong");
              })
              .finally(() => {
                this.saveLoading=false;
              });

        }
      }else{
        this.$snotify.error("Oops something went wrong");
      }
    },
      markAsPaid(enrollment) {
        if(enrollment){
            let data={
                payment_method:'offline',
                paid_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                remarks:this.mark_as_paid ? this.remarks : null,
            };
            this.saveLoading=true;
            enrollmentService
                .markAsPaid(enrollment.id,data)
                .then(response => {
                    if(response.data.enrollment && response.data.mark_as_paid){
                        this.openUploadDocumentAndPhotoDialog(response.data.enrollment,true);
                    }


                })
                .catch(error => {
                    this.displayMessage("error")
                })
                .finally(() => {
                    this.saveLoading=true;
                });
        }
      },
    validateWhileStep(){
      this.$v.$touch();
      //redirecting to step if validate occur
      if (this.$v.enrolmentDetail.$error) {
        this.step= 1;
        setTimeout(() => {
          this.$v.enrolmentDetail.$reset();
        }, 15000);
      }
      if (this.$v.dob_bs_year.$error || this.$v.dob_bs_month.$error || this.$v.dob_bs_day.$error) {
        this.step= 1;
        setTimeout(() => {
          this.$v.dob_bs_year.$reset();
          this.$v.dob_bs_month.$reset();
          this.$v.dob_bs_day.$reset();
        }, 15000);
      }

      if (this.$v.parent_information.$error) {
        this.step= 1;
        setTimeout(() => {
          this.$v.parent_information.$reset();
        }, 15000);
      }
      if (this.$v.permanent_address.$error) {
        this.step= 1;
        setTimeout(() => {
          this.$v.permanent_address.$reset();
        }, 15000);
      }
        if (this.$v.temporary_address.$error) {
            this.step= 1;
            setTimeout(() => {
                this.$v.temporary_address.$reset();
            }, 15000);
        }
      if (this.$v.educational_information.$error) {
        this.step= 2;
        setTimeout(() => {
          this.$v.educational_information.$reset();
        }, 15000);
      }
      if (this.$v.additional_details.awards_and_eca.$error) {
        this.step= 3;
        setTimeout(() => {
          this.$v.additional_details.awards_and_eca.$reset();
        }, 15000);
      }
      if (this.$v.additional_details.additional_information.$error) {
        this.step=4;
        setTimeout(() => {
          this.$v.additional_details.additional_information.$reset();
        }, 15000);
      }

      if ( this.$v.other_information.$error) {
        this.step=4;
        setTimeout(() => {
          this.$v.other_information.$reset();
        }, 15000);
      }
    },
    //Address
    getAllProvinces(){
      essentialService
          .getProvinces()
          .then((response) => {
            this.provinces=response.data;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          })
          .finally(() => {

          });
    },
    //Address
    getAllDistrictsByProvince(){
      if(this.permanent_address.province){
        this.permanent_address.district=null;
        this.permanent_address.city=null;
        this.permanent_address.ward=null;
        this.permanent_address.area=null;
        this.districtLoading=true;
        essentialService
            .getAllDistrictsByProvince(this.permanent_address.province)
            .then((response) => {
              this.districts=response.data;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            })
            .finally(() => {
                this.districtLoading=false;
            });
      }
    },
      getAllTemporaryDistrictsByProvince(){
          if(this.temporary_address.province){
              this.temporary_address.district=null;
              this.temporary_address.city=null;
              this.temporary_address.ward=null;
              this.temporary_address.area=null;
              this.temporaryDistrictLoading=true;
              essentialService
                  .getAllDistrictsByProvince(this.temporary_address.province)
                  .then((response) => {
                      this.temporary_districts=response.data;
                  })
                  .catch((err) => {
                      this.$snotify.error("Oops something went wrong");
                  })
                  .finally(() => {
                      this.temporaryDistrictLoading=false;
                  });
          }
      },

    //Address
    getMunicipalitiesByDistrict(){
      if(this.permanent_address.district){
        this.permanent_address.city=null;
        this.permanent_address.ward=null;
        this.permanent_address.area=null;
        this.municipalityLoading=true;
        essentialService
            .getAllMunicipalitiesByDistrict(this.permanent_address.district)
            .then((response) => {
              this.municipalities=response.data;
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            })
            .finally(() => {
                this.municipalityLoading=false;
            });
      }
    },
      getTemporaryMunicipalitiesByDistrict(){
          if(this.temporary_address.district){
              this.temporary_address.city=null;
              this.temporary_address.ward=null;
              this.temporary_address.area=null;
              this.temporaryMunicipalityLoading=true;
              essentialService
                  .getAllMunicipalitiesByDistrict(this.temporary_address.district)
                  .then((response) => {
                      this.temporary_municipalities=response.data;
                  })
                  .catch((err) => {
                      this.$snotify.error("Oops something went wrong");
                  })
                  .finally(() => {
                    this.temporaryMunicipalityLoading=false;
                  });
          }
      },
    convertAdToBs() {
      let data = {
        dob: this.enrolmentDetail.dob,
      };
      enrollmentService
          .convertAdToBs(data)
          .then((response) => {
            this.enrolmentDetail.dob_bs = response.data.dob;
          })
          .catch((error) => {
            //console.log(error);
          });
    },
    convertBsToAd() {
          let data = {
              dob: this.enrolmentDetail.dob_bs,
          };
          enrollmentService
              .convertBsToAd(data)
              .then((response) => {
                  this.enrolmentDetail.dob = response.data.dob_bs;
              })
              .catch((error) => {
                  //console.log(error);
              });
      },

    //Entrance Exam
    getAllEntranceExam(){
      if(this.pre_registration){
        entranceExam
            .getAllEntranceExam(this.pre_registration.id)
            .then((response) => {
              this.entrance_dates = response.data.entrances
            })
            .catch((error) => {
              //console.log(error);
            });
      }
    },
    goToProgramPage(){
      this.$router.push({
        name: "program",
      });
    },
      buildDateOfBirth() {
          if (this.dob_bs_year!='' && this.dob_bs_month!='' && this.dob_bs_day!='') {
              this.enrolmentDetail.dob_bs = this.dob_bs_year+'-'+this.dob_bs_month+'-'+this.dob_bs_day;
              if(this.enrolmentDetail.dob_bs){
                  this.convertBsToAd();
              }
          }
      },
    getNepaliYears() {
          this.nepaliYearsLoading=true;
          essentialService
              .getYears()
              .then(response => {
                  this.years = response.data.years;
              })
              .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
              })
              .finally(() => {
                  this.nepaliYearsLoading = false;
              });
      },
      getNepaliMonth() {
          this.nepaliMonthsLoading=true;
          essentialService
              .getMonths()
              .then(response => {
                  this.months = response.data.months;
              })
              .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
              })
              .finally(() => {
                  this.nepaliMonthsLoading = false;
              });
      },
      getDays() {
          this.daysLoading=true;
          essentialService
              .getDays()
              .then(response => {
                  this.days = response.data.days;
              })
              .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
              })
              .finally(() => {
                  this.daysLoading = false;
              });
      },
      changeDOBFormat(){
          this.enrolmentDetail.dob_bs=null;
          this.enrolmentDetail.dob=null;
          this.dob_bs_year=null;
          this.dob_bs_month=null;
          this.dob_bs_day=null;
      },
      changeTemporaryAddress(){
        if(this.copy_permanent_address){
            if(this.permanent_address.province){
                this.temporary_address.province=this.permanent_address.province;
                this.getAllTemporaryDistrictsByProvince();
            }
            if(this.permanent_address.district){
                this.temporary_address.district=this.permanent_address.district;
                this.getTemporaryMunicipalitiesByDistrict();
            }
            if(this.permanent_address.city){
                this.temporary_address.city=this.permanent_address.city;

            }
            if(this.permanent_address.ward){
                this.temporary_address.ward=this.permanent_address.ward;
            }
            if(this.permanent_address.area){
                this.temporary_address.area=this.permanent_address.area;
            }
            if(this.permanent_address.other_address){
                this.temporary_address.other_address=this.permanent_address.other_address;
            }
        }else{
            this.temporary_address.country='nepal';
            this.temporary_address.province=null;
            this.temporary_address.district=null;
            this.temporary_address.city=null;
            this.temporary_address.ward=null;
            this.temporary_address.area=null;
            this.temporary_address.other_address=null;
            this.temporary_districts=[];
            this.temporary_municipalities=[];

        }
      },
      openUploadDocumentAndPhotoDialog(enrollment=null,paymentStatus){
        this.$refs['document-and-photo'].openUploadDocumentAndPhotoDialog(enrollment,paymentStatus,this.remarks);
      },
      getEducationalDistrictByProvince(provinceId,index=null){
          if(provinceId){
              essentialService
                  .getAllDistrictsByProvince(provinceId)
                  .then((response) => {
                      this.$set(this.educational_districts, provinceId, response.data);
                      this.educational_information.academic_backgrounds[index].district=null;
                      if( this.educational_information.academic_backgrounds[index] && this.educational_information.academic_backgrounds[index].city){
                          this.educational_information.academic_backgrounds[index].city=null;
                      }
                      if( this.educational_information.academic_backgrounds[index] && this.educational_information.academic_backgrounds[index].ward){
                          this.educational_information.academic_backgrounds[index].ward=null;
                      }

                      if( this.educational_information.academic_backgrounds[index] && this.educational_information.academic_backgrounds[index].area) {
                          this.educational_information.academic_backgrounds[index].area = null;
                      }

                  })
                  .catch((err) => {

                      this.$snotify.error("Oops something went wrong 13");
                  })
                  .finally(() => {

                  });
          }

      },
      getEducationalDistricts(provinceId){
          if(provinceId){
              return this.educational_districts[provinceId] || [];
          }

      },
      getEducationalMunicipalitiesByDistrict(districtId,index=null){
          if(districtId){
              essentialService
                  .getAllMunicipalitiesByDistrict(districtId)
                  .then((response) => {
                      this.$set(this.educational_municipalities, districtId, response.data);
                      if( this.educational_information.academic_backgrounds[index] && this.educational_information.academic_backgrounds[index].city){
                          this.educational_information.academic_backgrounds[index].city=null;
                      }
                      if( this.educational_information.academic_backgrounds[index] && this.educational_information.academic_backgrounds[index].ward){
                          this.educational_information.academic_backgrounds[index].ward=null;
                      }

                      if( this.educational_information.academic_backgrounds[index] && this.educational_information.academic_backgrounds[index].area) {
                          this.educational_information.academic_backgrounds[index].area = null;
                      }


                  })
                  .catch((err) => {
                      console.log(err)
                      this.$snotify.error("Oops something went wrong 14");
                  })
                  .finally(() => {

                  });
          }

      },
      getEducationalMunicipalities(districtId){
          if(districtId){
              return this.educational_municipalities[districtId] || [];
          }

      },
  },
  mounted() {
    this.getAllProvinces();

    this.getPreregistrationLevel();
  },
  computed:{
    wards() {
      let start = 1;
      let wards = [];
      for (start; start < 33; start++) {
        wards.push(start);
      }
      return wards
    },
    completion_years() {
      let years = [];
      let thisYear = this.$moment().format("YYYY")
      for (let i = 0; i <= 15; i++) {
        years.push(thisYear - i);
      }
      return years;
    },
    enrollmentId() {
      return this.$route.params.enrollmentId;
    },
    basic_info_rule(){
      let rule = {
        first_name: {required},
        last_name: {required},
        email: {required},
        phone: {required, isPhone},
        nationality: {required},
        // entrance_date_time: {required},
        gender: {required},
      }
        if (this.dob_in_ad) {
            rule.dob = {required}
        } else {
            rule.dob_bs = {required}
        }
      return rule
    },
    parent_information_rule(){

        let rule={
            father_name: {
                required: requiredIf(function() {
                    return !this.parent_information.mother_name && !this.parent_information.guardian_name
                })
            },
            father_contact_number: {
                required: requiredIf(function() {
                    return !this.parent_information.mother_contact_number && !this.parent_information.guardian_contact_number
                })
            },
            mother_name: {
                required: requiredIf(function() {
                    return !this.parent_information.father_name && !this.parent_information.guardian_name
                })
            },
            mother_contact_number: {
                required: requiredIf(function() {
                    return !this.parent_information.father_contact_number && !this.parent_information.guardian_contact_number
                })
            },
            guardian_name: {
                required: requiredIf(function() {
                    return !this.parent_information.father_name && !this.parent_information.mother_name
                })
            },
            guardian_contact_number: {
                required: requiredIf(function() {
                    return !this.parent_information.father_contact_number && !this.parent_information.mother_contact_number
                })
            },
        }
        return rule;
    },
    permanent_address_rule(){
      let rule = {
        country:{required},
      };
      if(this.permanent_address.country=='nepal'){
        rule.province={required}
      }
      if(this.permanent_address.country=='nepal'){
        rule.district={required}
      }
      if(this.permanent_address.country=='nepal'){
        rule.city={required}
      }
      if(this.permanent_address.country=='nepal'){
        rule.ward={required}
      }

      if(this.permanent_address.country=='other'){
        rule.other_address={required}
      }
      return rule
    },
      temporary_address_rule(){
          let rule = {
              country:{required},
          };
          if(this.temporary_address.country=='nepal'){
              rule.province={required}
          }
          if(this.temporary_address.country=='nepal'){
              rule.district={required}
          }
          if(this.temporary_address.country=='nepal'){
              rule.city={required}
          }
          if(this.temporary_address.country=='nepal'){
              rule.ward={required}
          }

          if(this.temporary_address.country=='other'){
              rule.other_address={required}
          }
          return rule
      },
    educational_information_rule() {
      let rule = {}
      /* rule.entrance_date_time = {required}*/
      rule.entrance_date = {required}
      if (this.pre_registration && this.pre_registration.enable_selectable_course_1) {
        rule.selectable_course_1 = {required}
      }
      if (this.pre_registration && this.pre_registration.enable_selectable_course_2) {
        rule.selectable_course_2 = {required}
      }
      if (this.pre_registration && this.pre_registration.enable_selectable_course_3) {
        rule.selectable_course_3 = {required}
      }
      if (this.pre_registration && this.pre_registration.enable_selectable_course_4) {
        rule.selectable_course_4 = {required}
      }
      if (this.pre_registration && this.pre_registration.shifts.length > 0) {
        rule.shift = {required}
      }
      if (this.educational_information && this.educational_information.academic_backgrounds.length > 0) {
        rule.academic_backgrounds = {
          $each: {
            institute_name: {required},
            completed_year: {required},
            exam_board: {required},
            gpa: {required},
              country: {required},
              province: {requiredIf: requiredIf(function (value) {
                      return value.country === 'nepal';
                  }),
              },
              district: {
                  requiredIf: requiredIf(function (value) {
                      return value.country === 'nepal';
                  }),
              },

              other_address: {
                  requiredIf: requiredIf(function (value) {
                      return value.country === 'other';
                  }),
              },
          }
        }
      }
      return rule;
    },
    awards_and_eca_rule() {
      let rule = {};
      if(this.extra_information.enable_award_eca && this.extra_information.award_and_eca.enabled_future_plans && this.additional_details.awards_and_eca.has_volunteered_organization){
        rule.volunteered_organization_text = {required}
      }
      return rule;
    },
    additional_information_rule(){
      let rule = {};
      if(this.extra_information.enable_additional_information){
        if(this.extra_information && this.extra_information.additional_information && this.extra_information.additional_information.enabled_applicant_repeated_class  && this.additional_details.additional_information  && this.additional_details.additional_information.has_applicant_repeated_class){
          rule.applicant_repeated_class_text={required}
        }
        if(this.extra_information && this.extra_information.additional_information && this.extra_information.additional_information.enabled_applicant_suspended && this.additional_details.additional_information  && this.additional_details.additional_information.has_applicant_suspended){
          rule.applicant_suspended_text={required}
        }
        if(this.extra_information && this.extra_information.additional_information && this.extra_information.additional_information.enabled_applicant_have_any_health_issue && this.additional_details.additional_information  && this.additional_details.additional_information.does_applicant_have_mental){
          rule.applicant_have_mental_text={required}
        }
        if(this.extra_information && this.extra_information.additional_information && this.extra_information.additional_information.enabled_applicant_take_medication && this.additional_details.additional_information  && this.additional_details.additional_information.does_applicant_take_medication){
          rule.applicant_take_medication_text={required}
        }
        if(this.extra_information && this.extra_information.additional_information && this.extra_information.additional_information.enabled_applicant_need_special_needs && this.additional_details.additional_information  && this.additional_details.additional_information.does_applicant_special_needs){
          rule.applicant_special_needs_text={required}
        }
      }
      return rule;
    },
    miscellany_rule(){
      let rule = {}
      if (this.pre_registration && this.pre_registration.recommended_options && this.pre_registration.recommended_options.length > 0) {
        rule.recomended_sources = {required}
      }

      if (this.pre_registration && this.pre_registration.enable_transportation_facility && this.other_information.transportation_facility) {
        rule.pickup_location = {required}
      }
      return rule;
    },

  }
}
</script>
<style scoped>
.badge-course {
    background: #f5b342;
    font-size: 13px;
    border-radius: 13px;
    font-weight: 500;
}
</style>