import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class UserEnrollmentEssentialService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }
    getProvinces() {
        let url = `${this.#api}/user-enrollment-essential/provinces`;
        return apiService.get(url);
    }
    getAllDistrictsByProvince(provinceId) {

        let url = `${this.#api}/user-enrollment-essential/province/${provinceId}/districts`
        return apiService.get(url);
    }
    getAllMunicipalitiesByDistrict(districtId)
    {
        let url = `${this.#api}/user-enrollment-essential/district/${districtId}/municipalities`
        return apiService.get(url);
    }
    getYears(){
        let url = `${this.#api}/user-enrollment-essential/get/np/years`
        return apiService.get(url)
    }
    getMonths()
    {
        let url = `${this.#api}/user-enrollment-essential/get/np/months`
        return apiService.get(url)
    }

    getDays()
    {
        let url = `${this.#api}/user-enrollment-essential/get/np/days`
        return apiService.get(url)
    }


}